<template>
<body ontouchstart>
  <div style="text-align: center;">
    <van-image
      style="margin: 45% 35% 3% 35%;"
      width="5rem"
      height="5rem"
      fit="cover"
      :src="newImg"
    />
    <span style="margin: auto 15% auto 15%;">糟糕，您的页面找不到了</span>
  </div>
</body>
</template>

<script>
export default {
  data() {
    return {
      newImg: require("../../assets/err/404.png"),
    };
  },
};
</script>

<style>
</style>
